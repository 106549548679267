<template>
  <div>
    <SideBar
      :navigation-items="navigation"
      :user-settings-route="userSettingsRoute"
      :handle-side-bar-open="handleSideBarOpen"
      :sidebar-open="sidebarOpen"
      :show-settings="false"
    />
    <TopNav
      :sidebar-open="sidebarOpen"
      :user-settings-route="userSettingsRoute"
      :handle-side-bar-open="handleSideBarOpen"
      :show-settings="false"
    />

    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
        <Toast />
      </div>
    </main>
    <Toast />
  </div>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
const sidebarOpen = ref(false);
const user = useSupabaseUser();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const navigation = [] as any[];
const userSettingsRoute = `/user-settings/${user.value?.id}`;

const handleSideBarOpen = () => {
  sidebarOpen.value = !sidebarOpen.value;
};
</script>

<style scoped></style>
